import React from 'react';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./FormSendButton.scss";

function FormSendButton() {
    const formStore = useFormStore();
    const configStore = useConfigStore();

    if (formStore.currentPageCanAutoNext()) {
        return null;
    }

    return (
        <button onClick={() => {
            formStore.nextAction();
        }} type='button' className={`form-send-button ${!formStore.isCurrentStepValid() && "form-send-button--disabled"}`}>
            {configStore.translateText(formStore.canAlreadySubmitForm() ? "Submit" : "Next")}
        </button>
    );
}

export default FormSendButton;
