import create from 'zustand';

export type Session = {
    gleapId?: string;
    gleapHash?: string;
    name?: string;
    email?: string;
    userId?: string;
};

interface SessionState {
    session: Session;
    sdkKey: string;
    apiUrl: string;
    setSession: (session: Session) => void;
}

export const useSessionStore = create<SessionState>()((set) => ({
    session: {},
    sdkKey: "",
    apiUrl: "https://api.gleap.io",
    setSession: (session: Session) => {
        set({
            session,
        })
    },
}))