import create from 'zustand';

export type ConfigMenuItem = {
    title?: string;
    infoDescription?: string;
    description?: string;
    icon?: string;
    actionType?: string;
    actionBody?: string;
    color?: string;
};

export type Config = {
    color?: string;
    customCSS?: string;
    thankYouDuration?: number;
    backgroundColor?: string;
    headerColor?: string;
    buttonColor?: string;
    borderRadius?: number;
    logo?: string;
    buttonLogo?: string;
    hideBranding?: boolean;
    spamProtection?: boolean;
    enableConsoleLogs?: boolean;
    enableReplays?: boolean;
    replaysInterval?: number;
    enableNetworkLogs?: boolean;
    networkLogPropsToIgnore?: string[];
    enablePrivacyPolicy?: boolean;
    enableShortcuts?: boolean;
    privacyPolicyURL?: string;
    enableMenu?: boolean;
    menuItems?: ConfigMenuItem[];
    activationMethodFeedbackButton?: boolean;
    activationMethodShake?: boolean;
    activationMethodScreenshotGesture?: boolean;
    enableCrashDetector?: boolean;
    crashDetectorIsSilent?: boolean;
    enableRageClickDetector?: boolean;
    rageClickDetectorIsSilent?: boolean;
    enableNetworkLogFilters?: boolean;
    jsMajorVersion?: string;
    enableIntercomCompatibilityMode?: boolean;
    hideWavingHandAfterName?: boolean;
    hideUsersName?: boolean;
    widgetButtonText?: string;
    widgetInfoTitle?: string;
    widgetInfoSubtitle?: string;
    widgetInfoDialogSubtitle?: string;
    showInfoPopup?: boolean;
    feedbackButtonPosition?: string;
    customTranslations?: any;
};

interface ConfigState {
    config: Config;
    isApp: boolean;
    overrideLanguage: string;
    getUIColor: () => string;
    isRTLLang: () => boolean;
    translateText: (key?: string) => string;
    getHeaderColor: () => string;
    getBackgroundColor: () => string;
    getButtonColor: () => string;
    getBorderRadius: (multiplier?: number) => number;
}

export const useConfigStore = create<ConfigState>()((set, get) => ({
    config: {},
    isApp: false,
    overrideLanguage: "",
    translateText: (key) => {
        if (!key) {
            return "";
        }

        const { overrideLanguage, config: { customTranslations } } = get();

        var language = "en";
        if (typeof navigator.language !== "undefined") {
            language = navigator.language.substring(0, 2).toLowerCase();
        }
        if (overrideLanguage && overrideLanguage !== "") {
            language = overrideLanguage.toLowerCase();
        }

        if (typeof customTranslations === "undefined") {
            return key;
        }

        const searchForTranslationTable = (langKey: string) => {
            var customTranslation = null;
            const translationKeys = Object.keys(customTranslations);
            for (var i = 0; i < translationKeys.length; i++) {
                const translationKey = translationKeys[i];
                if (langKey && translationKey && langKey === translationKey.toLowerCase()) {
                    if (customTranslations[translationKey]) {
                        customTranslation = customTranslations[translationKey];
                    }
                }
            }

            return customTranslation;
        }

        var customTranslation = searchForTranslationTable(language);

        // Extended search for language match only.
        if (!customTranslation && language) {
            const langKeys = language.split("-");
            if (langKeys && langKeys.length > 1) {
                customTranslation = searchForTranslationTable(langKeys[0]);
            }
        }

        if (customTranslation && customTranslation[key]) {
            return customTranslation[key];
        }

        return key;
    },
    isRTLLang: () => {
        return get().translateText("rtlLang") === "true";
    },
    getUIColor: () => {
        return get().config.color || '#000000';
    },
    getHeaderColor: () => {
        return get().config.headerColor || '#000000';
    },
    getBackgroundColor: () => {
        return get().config.backgroundColor || '#ffffff';
    },
    getButtonColor: () => {
        return get().config.buttonColor || '#000000';
    },
    getBorderRadius: (multiplier = 1) => {
        return Math.round((get().config.borderRadius || 20) * multiplier);
    }
}))