import "core-js/es/object/values";
import "core-js/es/string/replace-all";
import ReactDOM from "react-dom/client";
import App from "./Views/App/App";
import HeightUpdateManager from "./Helper/HeightUpdateManager";
import CommunicationManager from "./Helper/CommunicationManager";
import "./index.scss";

var communicationInitialized = false;
const initializeCommunication = () => {
  if (communicationInitialized) {
    return;
  }
  communicationInitialized = true;
  HeightUpdateManager.getInstance().start();
  CommunicationManager.getInstance().sendMessage({
    name: "ping"
  });
}
initializeCommunication();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <App />
);
