import React, { useState } from 'react';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import { useDropzone } from 'react-dropzone';
import "./UploadFormItem.scss";
import { useSessionStore } from '../../Stores/Session';
import FormLoading from '../FormLoading/FormLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faXmark } from '@fortawesome/pro-regular-svg-icons';

function UploadFormItem({ formItem }: { formItem: any }) {
    const sessionStore = useSessionStore();
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        multiple: false,
        onDrop: (acceptedFiles) => {
            setError(false);

            if (
                acceptedFiles &&
                acceptedFiles.length > 0
            ) {
                var file = acceptedFiles[0];
                if (file.size / 1024 / 1024 > 10) {
                    setError(true);
                    return;
                }

                var formData = new FormData();
                formData.append("file", file);

                var xhr = new XMLHttpRequest();
                xhr.open(
                    "POST",
                    sessionStore.apiUrl + "/uploads/attachments"
                );
                if (sessionStore.session) {
                    xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
                    xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
                    xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
                }

                xhr.onerror = function () {
                    setError(true);
                    setUploading(false);
                };
                xhr.onreadystatechange = function () {
                    if (
                        xhr.readyState == 4 &&
                        xhr.status == 200 &&
                        xhr.responseText
                    ) {
                        setError(false);
                        setUploading(false);
                        try {
                            const data = JSON.parse(xhr.responseText);
                            if (data.fileUrls && data.fileUrls.length > 0) {
                                const dataUrl = data.fileUrls[0];
                                setFormData(formItem.name, {
                                    value: dataUrl,
                                    dirty: true,
                                    fileName: file.name
                                });
                            }
                        } catch (exp) { }
                    } else {
                        setError(true);
                    }

                    setUploading(false);
                };

                setUploading(true);
                xhr.send(formData);
            }
        }
    });
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderDropzone = () => {
        if (uploading) {
            return <div className='upload-form-item-loading'><FormLoading /></div>;
        }

        if (fieldFormData && fieldFormData.value && fieldFormData.fileName) {
            return (
                <div className='file-added-button'>
                    <FontAwesomeIcon icon={faPaperclip} />
                    <span>{fieldFormData.fileName}</span>
                    <div className='file-added-button-remove' onClick={() => {
                        setFormData(formItem.name, {
                            value: undefined,
                            dirty: true,
                            fileName: undefined
                        });
                    }}>
                        <FontAwesomeIcon icon={faXmark} /></div>
                </div >);
        }

        return (<div {...getRootProps({ className: `dropzone ${isDragAccept && "dropzone--accept"} ${isDragReject && "dropzone--reject"}` })}>
            <input {...getInputProps()} />
            <p>{configStore.translateText("Choose a file or drag it here.")}</p>
        </div>);
    }

    return (
        <div className="upload-form-item">
            {renderDropzone()}
        </div>
    );
}

export default UploadFormItem;
