import React, { useEffect } from 'react';
import { useConfigStore } from '../../Stores/Config';
import TextAreaFormItem from '../TextAreaFormItem/TextAreaFormItem';
import CaptureFormItem from '../CaptureFormItem/CaptureFormItem';
import TextFormItem from '../TextFormItem/TextFormItem';
import MultipleChoiceFormItem from '../MultipleChoiceFormItem/MultipleChoiceFormItem';
import OneToFiveFormItem from '../OneToFiveFormItem/OneToFiveFormItem';
import RatingFormItem from '../RatingFormItem/RatingFormItem';
import UploadFormItem from '../UploadFormItem/UploadFormItem';
import CheckboxFormItem from '../CheckboxFormItem/CheckboxFormItem';
import "./FormItem.scss";
import { useFormStore } from '../../Stores/Form';
import { loadFromGleapCache } from '../../Helper/CacheReader';
import { useSessionStore } from '../../Stores/Session';

function FormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const setFormData = useFormStore(state => state.setFormData);
    const formData = useFormStore(state => state.formData);
    const sessionStore = useSessionStore();
    const fieldFormData = formData[formItem.name];

    useEffect(() => {
        if (formItem) {
            if (formItem.defaultValue) {
                setFormData(formItem.name, {
                    value: formItem.defaultValue,
                    dirty: true,
                    hidden: formItem.hideOnDefaultSet === true
                });
            } else {
                if (formItem.remember) {
                    const data = loadFromGleapCache(`formitem-${sessionStore.sdkKey}-${formItem.name}`);
                    if (data && data.value && data.value.length > 0) {
                        setFormData(formItem.name, {
                            value: data.value,
                            dirty: true
                        });
                    }
                }
            }
        }
    }, [formItem]);

    const renderFormWidget = () => {
        switch (formItem.type) {
            case "text":
                return <TextFormItem formItem={formItem} />;
            case "textarea":
                return <TextAreaFormItem formItem={formItem} />;
            case "capture":
                return <CaptureFormItem formItem={formItem} />;
            case "onetofive":
                return <OneToFiveFormItem formItem={formItem} />;
            case "rating":
                return <RatingFormItem formItem={formItem} />;
            case "multiplechoice":
                return <MultipleChoiceFormItem formItem={formItem} />;
            case "upload":
                return <UploadFormItem formItem={formItem} />;
            case "privacypolicy":
                return <CheckboxFormItem formItem={formItem} />;
            default:
                return <div>{formItem.type}</div>;
        }
    }

    if (fieldFormData && fieldFormData.hidden) {
        return null;
    }

    return (
        <div className={`form-item form-item--${formItem.key}`}>
            {formItem.description && formItem.description.length > 0 && (
                <div className='form-item-description'>
                    {configStore.translateText(formItem.description)}
                </div>
            )}
            {formItem.title && formItem.title.length > 0 && (
                <div className='form-item-label'>
                    {configStore.translateText(formItem.title)}{formItem.required && <span className='form-item-label--required'>*</span>}
                </div>
            )}
            {renderFormWidget()}
        </div>
    );
}

export default FormItem;
