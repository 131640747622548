import React, { useState } from 'react';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./MultipleChoiceFormItem.scss";

function MultipleChoiceFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderOption = (option: any) => {
        return (<label key={option}>
            <input type="radio" defaultChecked={fieldFormData && fieldFormData.value === option} value={option} name={formItem.name} onClick={(() => {
                setFormData(formItem.name, {
                    value: option,
                    dirty: true
                }, true);
            })} /><span>{option}</span>
        </label>);
    };

    return (
        <div className="multiple-choice-form-item">
            {formItem.choices.map((choice: any) => (
                renderOption(choice)
            ))}
        </div>
    );
}

export default MultipleChoiceFormItem;
