import React from 'react';
import Communicator from '../../Helper/Communicator';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./CheckboxFormItem.scss";

function CheckboxFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);
    let privacyURL = configStore.config.privacyPolicyURL;
    if (!configStore.config.privacyPolicyURL || configStore.config.privacyPolicyURL.length === 0) {
        privacyURL = "https://gleap.io/privacy-policy";
    }

    return (
        <div className="checkbox-form-item">
            <label className="form-control">
                <input
                    type="checkbox"
                    name="checkbox-checked"
                    defaultChecked={fieldFormData && fieldFormData.value === "checked"}
                    onChange={(e) => {
                        var checked = "";
                        if (e.currentTarget.checked) {
                            checked = "checked";
                        }
                        setFormData(formItem.name, {
                            value: checked,
                            dirty: true
                        });
                    }} />
                <span>
                    {configStore.translateText(
                        "I read and accept the "
                    )}<a onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        Communicator.openURL(privacyURL!);
                    }}>{configStore.translateText(
                        "privacy policy"
                    )}</a>.
                </span>
            </label>
        </div>
    );
}

export default CheckboxFormItem;
