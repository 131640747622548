import CommunicationManager from "./CommunicationManager";

export default class Communicator {
    static openURL(url: string) {
        CommunicationManager.getInstance().sendMessage({
            name: "open-url",
            data: url
        });
    }

    static runCustomAction(action: string) {
        CommunicationManager.getInstance().sendMessage({
            name: "run-custom-action",
            data: action
        });
    }

    static closeWidget() {
        CommunicationManager.getInstance().sendMessage({
            name: "close-widget",
        });
    }


    static notifyEvent(name: string, data?: any) {
        CommunicationManager.getInstance().sendMessage({
            name: "notify-event",
            data: {
                type: name,
                data: data,
            }
        });
    }
}
