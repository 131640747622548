import { useActionStore } from "../Stores/Actions";
import { useConfigStore } from "../Stores/Config";
import { useSessionStore } from "../Stores/Session";
import { useFormStore } from "../Stores/Form";
import { useRouterStore } from "../Stores/Router";

const FORM_SENT_TIMEOUT = 3000;

const createRecaptcha = function () {
    let script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    script.id = 'recaptchaScript';
    script.src = 'https://www.recaptcha.net/recaptcha/api.js?render=6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT';
    document.getElementsByTagName('head')[0].appendChild(script)
}

export default class CommunicationManager {
    private static _instance: CommunicationManager;
    private static injectedScript: boolean = false;

    static getInstance() {
        if (!this._instance) {
            this._instance = new CommunicationManager();
        }
        return this._instance;
    }

    constructor() {
        this.startMessageListener();
    }

    sendMessage(data: any) {
        (window as any).top.postMessage(JSON.stringify(data), "*");
    }

    startMessageListener() {
        // Add window message listener.
        window.addEventListener("message", (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.name === "config-update") {
                    if (data.data.config) {
                        useConfigStore.setState({
                            config: data.data.config
                        });

                        if (data.data.config && data.data.config.spamProtection && !CommunicationManager.injectedScript) {
                            CommunicationManager.injectedScript = true;
                            createRecaptcha();
                        }
                    }
                    if (data.data.isApp) {
                        useConfigStore.setState({
                            isApp: true
                        });
                    }
                    if (data.data.overrideLanguage) {
                        useConfigStore.setState({
                            overrideLanguage: data.data.overrideLanguage,
                        });
                    }
                    if (data.data.actions) {
                        useActionStore.setState({
                            actions: data.data.actions
                        });
                    }
                }
                if (data.name === "session-update") {
                    useSessionStore.setState({
                        session: data.data.sessionData,
                        apiUrl: data.data.apiUrl,
                        sdkKey: data.data.sdkKey,
                    });
                }
                if (data.name === "start-feedbackflow") {
                    useFormStore.setState({
                        actionOutboundId: data.data.actionOutboundId,
                        hideBackButton: data.data.hideBackButton,
                    });
                    useRouterStore.getState().setPage("flow", {
                        flowId: data.data.flow,
                    });
                }
                if (data.name === "prefill-form-data") {
                    useFormStore.getState().setPreFillFormData(data.data);
                }
                if (data.name === "feedback-sent") {
                    useFormStore.getState().setFormSent();

                    let formSentTimeout = useConfigStore.getState().config.thankYouDuration || FORM_SENT_TIMEOUT;

                    setTimeout(() => {
                        this.sendMessage({
                            name: "close-widget"
                        });
                        useFormStore.getState().clearFeedbackFlowOptions();
                        useRouterStore.getState().setPage("menu");
                    }, formSentTimeout);
                }
                if (data.name === "feedback-sending-failed") {
                    useFormStore.setState({
                        formSent: false,
                        sendingForm: false,
                        formError: data.data
                    });
                }
                if (data.name === "set-form-data") {
                    useFormStore.getState().setFormData(data.data.formKey, data.data.data);
                }
            } catch (exp) { }
        });
    }
}
