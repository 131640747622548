import React, { useState } from 'react';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./RatingFormItem.scss";
import { RatingOptionAngry, RatingOptionGood, RatingOptionHappy, RatingOptionOk, RatingOptionSad } from './RatingOptions';

function RatingFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderOption = (value: any) => {
        const onItemClick = (() => {
            setFormData(formItem.name, {
                value,
                dirty: true
            }, true);
        })
        const isActive = fieldFormData && fieldFormData.value === value;
        if (value === 1) {
            return <RatingOptionAngry key={value} active={isActive} onClick={onItemClick} />
        } else if (value === 2.5) {
            return <RatingOptionSad key={value} active={isActive} onClick={onItemClick} />
        } else if (value === 5) {
            return <RatingOptionOk key={value} active={isActive} onClick={onItemClick} />
        } else if (value === 7.5) {
            return <RatingOptionGood key={value} active={isActive} onClick={onItemClick} />
        } else {
            return <RatingOptionHappy key={value} active={isActive} onClick={onItemClick} />
        };
    };

    return (
        <div className="rating-form-item">
            <ul className="emojigroup">
                {[1, 2.5, 5, 7.5, 10].map((value: any) => renderOption(value))}
            </ul>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                    <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                    <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
            </svg>
        </div>
    );
}

export default RatingFormItem;
