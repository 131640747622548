import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./OneToFiveFormItem.scss";

function OneToFiveFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const renderOption = (value: any) => {
        return (<div className={`one-to-five-form-item-item ${(fieldFormData && fieldFormData.value === value) && "one-to-five-form-item-item--active"}`} key={value} onClick={(() => {
            setFormData(formItem.name, {
                value,
                dirty: true
            }, true);
        })}>
            <span>{value}</span>
        </div>);
    };

    return (
        <div className="one-to-five-form-item">
            <div className="one-to-five-form-item-items">
                {[1, 2, 3, 4, 5].map((value: any) => renderOption(value))}
            </div>
            <div className="one-to-five-form-item-labels">
                <span>{configStore.translateText(formItem.lowestValueLabel)}</span>
                <span>{configStore.translateText(formItem.highestValueLabel)}</span>
            </div>
        </div>
    );
}

export default OneToFiveFormItem;
