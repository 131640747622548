import React, { useEffect, useState } from 'react';
import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./TextFormItem.scss";

function TextFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    var classes = "text-form-item-input";
    if (fieldFormData && fieldFormData.dirty && !fieldFormData.valid && !focused) {
        classes += " text-form-item-input--invalid";
    }

    return (
        <div className='text-form-item'>
            <input
                className={classes}
                type={formItem.inputtype}
                value={fieldFormData && fieldFormData.value ? fieldFormData.value : ""}
                name={formItem.name}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={configStore.translateText(formItem.placeholder)}
                onChange={(e: any) => {
                    setFormData(formItem.name, {
                        value: e.target.value,
                        dirty: true
                    });
                }} />
        </div>
    );
}

export default TextFormItem;
