import React, { useState } from 'react';
import { useFormStore } from '../../Stores/Form';
import { useConfigStore } from '../../Stores/Config';
import "./TextAreaFormItem.scss";

function TextAreaFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const minRows = 1;
    const maxRows = 8;
    const [rows, setRows] = useState(minRows + 1);
    const setFormData = useFormStore(state => state.setFormData);

    var classes = "textarea-form-item-textarea";
    if (fieldFormData && fieldFormData.dirty && !fieldFormData.valid) {
        classes += " textarea-form-item-textarea--invalid";
    }

    return (
        <div className='textarea-form-item'>
            <textarea
                className={classes}
                defaultValue={fieldFormData && fieldFormData.value}
                name={formItem.name}
                placeholder={configStore.translateText(formItem.placeholder)}
                rows={rows}
                onChange={(e: any) => {
                    setFormData(formItem.name, {
                        value: e.target.value,
                        dirty: true
                    });

                    const textareaLineHeight = 19;

                    const previousRows = e.target.rows;
                    e.target.rows = minRows;

                    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

                    if (currentRows === previousRows) {
                        e.target.rows = currentRows;
                    }

                    if (currentRows >= maxRows) {
                        e.target.rows = maxRows;
                        e.target.scrollTop = e.target.scrollHeight;
                    }

                    setRows(currentRows < maxRows ? currentRows : maxRows);
                }} />
        </div>
    );
}

export default TextAreaFormItem;
