import Communicator from '../../Helper/Communicator';
import { ConfigMenuItem, useConfigStore } from '../../Stores/Config';
import { useRouterStore } from '../../Stores/Router';
import "./WidgetMenuButton.scss";

function WidgetMenuButton({ menuItem, index }: {
    menuItem: ConfigMenuItem;
    index: number;
}) {
    const routerStore = useRouterStore();
    const configStore = useConfigStore();

    return (
        <div className="widget-menu-button" onClick={() => {
            if (menuItem.actionType === "CUSTOM_ACTION") {
                if (menuItem.actionBody) {
                    Communicator.runCustomAction(menuItem.actionBody);
                    Communicator.closeWidget();
                }
            } else if (menuItem.actionType === "REDIRECT_URL") {
                if (menuItem.actionBody) {
                    Communicator.openURL(menuItem.actionBody);
                    Communicator.closeWidget();
                }
            } else {
                routerStore.setPage("flow", {
                    flowId: menuItem.actionType,
                });
            }
        }}>
            <div className="widget-menu-button-icon" style={{
                backgroundColor: menuItem.color ?? "#000000",
            }}>
                <img src={menuItem.icon} alt={menuItem.title} />
            </div>
            <div className='widget-menu-button-labels'>
                <div className='widget-menu-button-title'>{configStore.translateText(menuItem.title)}</div>
                <div className='widget-menu-button-description'>{configStore.translateText(menuItem.description)}</div>
            </div>
        </div>
    );
}

export default WidgetMenuButton;
