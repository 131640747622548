import React from 'react';
import { ConfigMenuItem, useConfigStore } from '../../Stores/Config';
import WidgetMenuButton from '../WidgetMenuButton/WidgetMenuButton';
import "./WidgetMenu.scss";

function WidgetMenu() {
    const config = useConfigStore(state => state.config);

    return (
        <div className='widget-menu-buttons'>{config.menuItems?.map((menuItem: ConfigMenuItem, key: any) => {
            return (<WidgetMenuButton menuItem={menuItem} key={key} index={key} />);
        })}</div>
    );
}

export default WidgetMenu;
