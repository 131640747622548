import { useCallback, useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Menu from '../Menu/Menu';
import FeedbackFlow from '../FeedbackFlow/FeedbackFlow';
import './App.scss';
import CSSInjector from '../../Components/CSSInjector/CSSInjector';
import WidgetHeader from '../../Components/WidgetHeader/WidgetHeader';
import Communicator from '../../Helper/Communicator';
import { useRouterStore } from '../../Stores/Router';
import { debounce } from "lodash";
import { useConfigStore } from '../../Stores/Config';

export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => { ref.current = value })

  return ref.current as any
}

function App() {
  const routerStore = useRouterStore();
  const configStore = useConfigStore();

  const updateAnimationStatus = (animating: boolean) => {
    if (document && document.body) {
      if (animating) {
        document.body.classList.add("animating");
      } else {
        document.body.classList.remove("animating");
      }
    }

    const htmlRootNodes = document.getElementsByTagName('html');
    if (htmlRootNodes && htmlRootNodes.length > 0) {
      if (animating) {
        htmlRootNodes[0].classList.add("animating");
      } else {
        htmlRootNodes[0].classList.remove("animating");
      }
    }
  }

  const resetBodyScroll = () => {
    updateAnimationStatus(false);
  }

  const resetBodyScrollDebounced = useCallback(debounce(() => resetBodyScroll(), 1000), []);

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape") {
      Communicator.closeWidget();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (routerStore.currentPage) {
      updateAnimationStatus(true);
      resetBodyScrollDebounced();
    }
  }, [routerStore.currentPage]);

  const renderMainComponents = () => {
    if (routerStore.currentPage === "flow") {
      return <FeedbackFlow />;
    } else {
      return <Menu />;
    }
  }

  return (<>
    <CSSInjector />
    <div className="main-app" dir={configStore.isRTLLang() ? "rtl" : "ltr"}>
      <WidgetHeader />
      <div className={`content-container animation-type-${routerStore.navigationDirection}`}>
        {renderMainComponents()}
      </div>
    </div>
  </>);
}

export default App;
