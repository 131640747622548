import React from 'react';

function RatingOptionAngry({ onClick, active }: { onClick: () => void, active?: boolean }) {
    return (<li className={`emoji-item-angry ${active && "emoji-item--active"}`} onClick={onClick}>
        <div>
            <svg className="emoji-item-eye emoji-item-left">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-eye emoji-item-right">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-mouth">
                <use xlinkHref="#mouth" />
            </svg>
        </div>
    </li>);
}

function RatingOptionSad({ onClick, active }: { onClick: () => void, active?: boolean }) {
    return (<li className={`emoji-item-sad ${active && "emoji-item--active"}`} onClick={onClick}>
        <div>
            <svg className="emoji-item-eye emoji-item-left">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-eye emoji-item-right">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-mouth">
                <use xlinkHref="#mouth" />
            </svg>
        </div>
    </li>);
}

function RatingOptionOk({ onClick, active }: { onClick: () => void, active?: boolean }) {
    return (<li className={`emoji-item-ok ${active && "emoji-item--active"}`} onClick={onClick}>
        <div></div>
    </li>);
}

function RatingOptionGood({ onClick, active }: { onClick: () => void, active?: boolean }) {
    return (<li className={`emoji-item-good ${active && "emoji-item--active"}`} onClick={onClick}>
        <div>
            <svg className="emoji-item-eye emoji-item-left">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-eye emoji-item-right">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-mouth">
                <use xlinkHref="#mouth" />
            </svg>
        </div>
    </li>);
}

function RatingOptionHappy({ onClick, active }: { onClick: () => void, active?: boolean }) {
    return (<li className={`emoji-item-happy ${active && "emoji-item--active"}`} onClick={onClick}>
        <div>
            <svg className="emoji-item-eye emoji-item-left">
                <use xlinkHref="#eye" />
            </svg>
            <svg className="emoji-item-eye emoji-item-right">
                <use xlinkHref="#eye" />
            </svg>
        </div>
    </li>);
}

export { RatingOptionAngry, RatingOptionSad, RatingOptionOk, RatingOptionGood, RatingOptionHappy };