import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconButton.scss';

function IconButton({ onClick, icon, className }: { onClick: () => void, icon: any, className?: string; }) {
    return (
        <div className={`icon-button ${className}`} onClick={onClick}>
            <FontAwesomeIcon fontSize={18} icon={icon} />
        </div>
    );
}

export default IconButton;
