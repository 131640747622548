import create from 'zustand';

interface RouterState {
    navigationDirection: 'forward' | 'backward';
    currentPage: string;
    currentData: any;
    setPage: (page: string, data?: any) => void;
}

export const useRouterStore = create<RouterState>()((set, get) => ({
    currentPage: "menu",
    currentData: {},
    navigationDirection: "forward",
    setPage: (page: string, data: any) => {
        const navigationDirection = get().currentPage === "menu" ? "forward" : "backward";

        set({
            currentPage: page,
            currentData: data,
            navigationDirection: navigationDirection,
        });
    }
}));