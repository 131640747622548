import React from 'react';
import FormItem from '../FormItem/FormItem';
import FormSendButton from '../FormSendButton/FormSendButton';
import { useFormStore } from '../../Stores/Form';
import FormLoading from '../FormLoading/FormLoading';
import "./Form.scss";
import { useConfigStore } from '../../Stores/Config';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FormProgressBar from '../FormProgressBar/FormProgressBar';

function Form() {
    const formStore = useFormStore();
    const configStore = useConfigStore();

    if (!formStore.action || !formStore.action.form || formStore.sendingForm) {
        return (<div className='form-loading'><FormLoading /></div>);
    }

    if (formStore.formSent) {
        return (<div className='form-sent'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle cx="26" cy="26" r="25" fill="none"></circle>
                <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
            </svg>
            <div className='form-sent-label'>{configStore.translateText(formStore.action.thanksMessage ?? "Thank you for your feedback.")}</div>
        </div>);
    }

    const renderError = () => {
        if (formStore.formError) {
            return (<div className='form-error'>{configStore.translateText(formStore.formError)}</div>);
        }

        return null;
    }

    return (
        <>
            <FormProgressBar />
            {renderError()}
            <div className='form-container-main'>
                <TransitionGroup className={`form-container-animation--${formStore.navigationDirection}`}>
                    {formStore.getCurrentFormItems().map((formItem: any) => {
                        return (
                            <CSSTransition
                                key={formItem.name}
                                classNames={`form-item-animation`}
                                timeout={700}
                            >
                                <FormItem formItem={formItem} key={formItem.name} />
                            </CSSTransition>
                        );
                    })}
                    <FormSendButton />
                </TransitionGroup>
            </div>
        </>
    );
}

export default Form;
