import React from 'react';
import { useConfigStore } from '../../Stores/Config';
import { ReactComponent as GleapDefaultIcon } from '../../Assets/DefaultLogo.svg';
import { useSessionStore } from '../../Stores/Session';
import "./WidgetHeader.scss";
import IconButton from '../IconButton/IconButton';
import { useFormStore } from '../../Stores/Form';
import CommunicationManager from '../../Helper/CommunicationManager';
import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useRouterStore } from '../../Stores/Router';

function WidgetHeader() {
    const session = useSessionStore(state => state.session);
    const configStore = useConfigStore();
    const formStore = useFormStore();
    const routerStore = useRouterStore();
    const config = configStore.config;
    const hideBackButton = formStore.hideBackButton;

    const closeWidgetAndReset = () => {
        CommunicationManager.getInstance().sendMessage({
            name: "close-widget"
        });

        formStore.clearFeedbackFlowOptions();
        routerStore.setPage("menu");
    }

    const renderMainHeader = () => {
        const description = configStore.translateText(config.widgetInfoDialogSubtitle);
        const showUsername = typeof config.hideUsersName === "undefined" || !config.hideUsersName;
        return <div className='widget-header-main'>
            {config.logo && config.logo.length > 0 ?
                <img className='widget-header-main-logo' src={config.logo} alt="Logo" /> :
                <GleapDefaultIcon className='widget-header-main-logo' />
            }
            <div className='widget-header-main-title'>
                <span>{configStore.translateText("Hi")}</span>
                {showUsername && <span className='widget-header-main-title-name'>{session.name}</span>}
                {!config.hideWavingHandAfterName && <span>{"👋"}</span>}
            </div>
            {description && description.length > 0 &&
                <div className='widget-header-main-description'>
                    {description}
                </div>}
            <IconButton className='widget-header-main-close' icon={faXmark} onClick={() => {
                closeWidgetAndReset();
            }} />
        </div>;
    }

    const renderSubHeader = () => {
        return <div className='widget-header-sub'>
            {!hideBackButton &&
                <IconButton icon={faChevronLeft} onClick={() => {
                    if (formStore.currentStep > 0) {
                        formStore.showPrevStep();
                    } else {
                        routerStore.setPage("menu");
                        CommunicationManager.getInstance().sendMessage({
                            name: "cleanup-drawings"
                        });
                    }
                }} />}
            <div className='widget-header-sub-title'>{configStore.translateText(formStore.action?.title)}</div>
            <IconButton icon={faXmark} onClick={() => {
                closeWidgetAndReset();
            }} />
        </div>;
    }

    const showMainHeader = routerStore.currentPage === "menu";

    return (
        <div className="widget-header">
            {showMainHeader ? renderMainHeader() : renderSubHeader()}
        </div>
    );
}

export default WidgetHeader;
