import React from 'react';
import { useFormStore } from '../../Stores/Form';
import "./FormProgressBar.scss";

function FormProgressBar() {
    const formStore = useFormStore();
    if (formStore.isSurvey()) {
        return null;
    }

    const progress = formStore.getProgress();
    if (progress === 0) {
        return null;
    }

    return (
        <div className='progress-bar-container'>
            <div className='progress-bar-inner' style={{
                width: `${formStore.getProgress()}%`
            }}></div>
        </div>
    );
}

export default FormProgressBar;
