import create from 'zustand';

export type Action = {
    default: boolean;
    title: string;
    description: string;
    thanksMessage: string;
    form: any[];
    disableUserScreenshot: boolean;
    enableUserScreenRecording: boolean;
    singlePageForm: boolean;
    feedbackType: string;
    collectEmail: boolean;
    excludeData: any;
};

interface ActionState {
    actions: any,
    setActions: (actions: any) => void
}

export const useActionStore = create<ActionState>()((set) => ({
    actions: {},
    setActions: (actions: any) => {
        set({
            actions,
        })
    },
}))