import { useEffect, useState } from 'react';
import { useActionStore } from '../../Stores/Actions';
import Form from '../../Components/Form/Form';
import { useFormStore } from '../../Stores/Form';
import WidgetPoweredBy from '../../Components/WidgetPoweredBy/WidgetPoweredBy';
import { useRouterStore } from '../../Stores/Router';
import "./FeedbackFlow.scss";

function FeedbackFlow() {
    const routerStore = useRouterStore();
    const actions = useActionStore(state => state.actions);
    const [currentAction, setCurrentAction] = useState();
    const formStore = useFormStore();

    useEffect(() => {
        if (currentAction) {
            formStore.resetForm();
            formStore.setFormAction(currentAction);
            formStore.validateForm();
        }
    }, [currentAction]);

    useEffect(() => {
        if (actions && routerStore.currentData && routerStore.currentData.flowId) {
            const action = actions[routerStore.currentData.flowId];
            if (action) {
                setCurrentAction(action);
            }
        }
    }, [actions, routerStore.currentData]);

    return (
        <div className='feedback-flow'>
            <Form />
            <WidgetPoweredBy />
        </div>
    );
}

export default FeedbackFlow;
