import React from 'react';
import './FormLoading.css';
import { useConfigStore } from '../../Stores/Config';

function FormLoading() {
    const configStore = useConfigStore();

    return (
        <div className="gleap-loading">
            {[0, 1, 2, 3].map((_, i) => <div key={i} style={{
                borderBottomColor: configStore.getUIColor()
            }} />)}
        </div>
    );
}

export default FormLoading;
