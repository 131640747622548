import create from 'zustand';
import produce from "immer";
import CommunicationManager from '../Helper/CommunicationManager';
import { useSessionStore } from './Session';
import { saveToGleapCache } from '../Helper/CacheReader';
import Communicator from '../Helper/Communicator';
import { useConfigStore } from './Config';

const getTokenForAction = (action: string) => {
    const config = useConfigStore.getState().config;
    if (config && config.spamProtection) {
        return new Promise((resolve, reject) => {
            const grecaptcha = (window as any).grecaptcha;
            if (grecaptcha) {
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT', { action: 'submitfeedback' }).then(function (token: string) {
                        resolve(token);
                    }).catch(() => {
                        reject();
                    });
                });
            } else {
                reject();
            }
        });
    } else {
        return Promise.resolve(undefined);
    }
}

const validateEmail = (email: string) => {
    const matches = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    if (matches && matches.length > 0) {
        return true;
    }
    return false;
};

interface FormState {
    isValid: boolean;
    areStepsValid: any;
    action: any;
    preFillData: any;
    formData: any;
    sendingForm: boolean;
    formSent: boolean;
    formError?: string;
    currentStep: number;
    actionOutboundId?: string;
    hideBackButton?: boolean;
    navigationDirection: string;
    setPreFillFormData: (data: any) => void;
    setFormData: (formKey: string, data: any, autoNext?: boolean) => void;
    setFormAction: (action: any) => void;
    getCurrentFormItems: () => any[];
    getFormItemsForPage: (page: number) => any[];
    validateForm: () => void;
    resetForm: () => void;
    sendForm: () => void;
    isSurvey: () => boolean;
    showNextStep: () => void;
    showPrevStep: () => void;
    setFormSent: () => void;
    currentPageCanAutoNext: () => boolean;
    isCurrentStepValid: () => boolean;
    nextAction: () => void;
    clearFeedbackFlowOptions: () => void;
    canAlreadySubmitForm: () => boolean;
    getProgress: () => number;
}

const typeCanAutoNext = (type: string) => {
    switch (type) {
        case "text":
            return false;
        case "textarea":
            return false;
        case "capture":
            return false;
        case "onetofive":
            return true;
        case "rating":
            return true;
        case "multiplechoice":
            return true;
        case "upload":
            return false;
        case "privacypolicy":
            return false;
        default:
            return false;
    }
};

const isFieldValid = (field: any, fieldData: any) => {
    if (!field.required) {
        return true;
    }

    if (!field || !fieldData || !fieldData.value) {
        return false;
    }

    const fieldValue = fieldData.value;

    // Check text field
    if (field.type === "text" && fieldValue.length > 0) {
        if (field.inputtype === "email") {
            return validateEmail(fieldValue);
        }
        return true;
    }

    if (field.type === "textarea" && fieldValue.length > 0) {
        return true;
    }

    if (field.type === "multiplechoice" && fieldValue.length > 0) {
        return true;
    }

    if (field.type === "rating" && (fieldValue > 0 || fieldValue.length > 0)) {
        return true;
    }

    if (field.type === "upload" && fieldValue.length > 0) {
        return true;
    }

    if (field.type === "onetofive" && (fieldValue > 0 || fieldValue.length > 0)) {
        return true;
    }

    if (field.type === "privacypolicy" && fieldValue.length > 0) {
        return true;
    }

    return false;
}

export const useFormStore = create<FormState>()((set, get) => ({
    isValid: false,
    areStepsValid: {},
    sendingForm: false,
    formSent: false,
    formError: undefined,
    actionOutboundId: undefined,
    hideBackButton: false,
    action: undefined,
    navigationDirection: "forwards",
    formData: {},
    preFillData: {},
    currentStep: 0,
    clearFeedbackFlowOptions: () => {
        set({
            actionOutboundId: undefined,
            hideBackButton: false,
        });
    },
    isSurvey: () => {
        return (get().actionOutboundId ?? "").length > 0;
    },
    isCurrentStepValid: () => {
        const { areStepsValid, currentStep } = get();
        if (areStepsValid && areStepsValid[currentStep] === true) {
            return true;
        }
        return false;
    },
    nextAction: () => {
        const formStore = get();
        if (formStore.canAlreadySubmitForm()) {
            if (formStore.isValid) {
                formStore.sendForm();
            }
        } else {
            if (formStore.isCurrentStepValid()) {
                formStore.showNextStep();
            }
        }
    },
    getProgress: () => {
        if (!get().action.pages) {
            return 0;
        }

        return Math.round((get().currentStep + 1) / get().action.pages * 100);
    },
    canAlreadySubmitForm: () => {
        const formStore = get();
        return formStore.currentStep >= ((formStore.action?.pages ?? 0) - 1)
    },
    currentPageCanAutoNext: () => {
        var canAutoNext = true;
        const currentPageItems = get().getFormItemsForPage(get().currentStep);
        for (let i = 0; i < currentPageItems.length; i++) {
            const field = currentPageItems[i];
            if (!typeCanAutoNext(field.type)) {
                canAutoNext = false;
            }
        }
        if (currentPageItems.length > 1) {
            canAutoNext = false;
        }
        return canAutoNext;
    },
    getFormItemsForPage: (page: number) => {
        const { action } = get();
        const currentForm = action.form;
        if (!currentForm) {
            return [];
        }
        return currentForm.filter((item: any) => {
            if (item && item.page === page) {
                return true;
            }
            return false;
        });
    },
    getCurrentFormItems: () => {
        const { currentStep } = get();
        return get().getFormItemsForPage(currentStep);
    },
    sendForm: () => {
        const { isValid, formData } = get();
        if (!isValid) {
            return;
        }

        set({
            sendingForm: true
        });

        getTokenForAction("submitfeedback").then((token: any) => {
            // Map formdata to plain formdata object.
            var formDataToSend: any = {};
            var formDataKeys = Object.keys(formData);
            for (let i = 0; i < formDataKeys.length; i++) {
                const key = formDataKeys[i];
                if (key === "capture" || key === "privacypolicy") {
                    continue;
                }
                const field = formData[key];
                formDataToSend[key] = field.value;
            }

            var data: any = {
                formData: formDataToSend,
                action: get().action,
                outboundId: get().actionOutboundId,
                spamToken: token ? token : "notset",
            };

            // Send to communicator.
            CommunicationManager.getInstance().sendMessage({
                name: "send-feedback",
                data,
            });
        }).catch((error: any) => {
            set({
                formSent: false,
                sendingForm: false,
                formError: "Something went wrong. Please try again later."
            });
        });
    },
    showNextStep: () => {
        const { currentStep } = get();
        set({
            navigationDirection: "forwards",
            currentStep: currentStep + 1
        });
    },
    showPrevStep: () => {
        const { currentStep } = get();
        set({
            navigationDirection: "backwards",
            currentStep: currentStep - 1
        });
    },
    resetForm: () => {
        set({
            action: undefined,
            formData: {},
            currentStep: 0,
            isValid: false,
            areStepsValid: {},
            sendingForm: false,
            formSent: false,
            formError: undefined,
            navigationDirection: "forwards",
        });
    },
    setPreFillFormData: (data: any) => {
        set({
            preFillData: data
        });
    },
    setFormData: (formKey: string, data: any, autoNext = false) => {
        const formItemConfig = get().action.form.find((configItem: any) => configItem.name === formKey);
        if (!formItemConfig) {
            return;
        }

        if (formItemConfig.remember) {
            saveToGleapCache(`formitem-${useSessionStore.getState().sdkKey}-${formItemConfig.name}`, data);
        }

        set({
            formData: produce(get().formData, (draft: any) => {
                const newFieldData = {
                    ...draft[formKey],
                    ...data
                }
                draft[formKey] = {
                    ...newFieldData,
                    valid: isFieldValid(formItemConfig, newFieldData),
                };
            })
        });

        get().validateForm();

        if (autoNext && get().currentPageCanAutoNext()) {
            setTimeout(() => {
                get().nextAction();
            }, 300);
        }
    },
    validateForm: () => {
        const formData = get().formData;

        // areStepsValid
        var isFormValid = true;
        var areFormStepsValid: any = {};

        for (let step = 0; step < get().action.pages; step++) {
            let formConfig = get().getFormItemsForPage(step);
            areFormStepsValid[step] = true;
            for (let i = 0; i < formConfig.length; i++) {
                const field = formConfig[i];
                const fieldData = formData[field.name];
                if (!isFieldValid(field, fieldData)) {
                    isFormValid = false;
                    areFormStepsValid[step] = false;
                }
            }
        }

        set({
            isValid: isFormValid,
            areStepsValid: areFormStepsValid
        });
    },
    setFormAction: (action: any) => {
        action = JSON.parse(JSON.stringify(action));

        // Notify flow started.
        Communicator.notifyEvent("flow-started", action);

        // Transform form data.
        if (action.form && action.form.length > 0) {
            // Cleanup form from unsupported items.
            let newFormArray = [];
            for (var i = 0; i < action.form.length; i++) {
                var feedbackOption = action.form[i];
                if (
                    feedbackOption &&
                    feedbackOption.type !== "privacypolicy" &&
                    feedbackOption.type !== "spacer" &&
                    feedbackOption.type !== "submit" &&
                    feedbackOption.name !== "reportedBy"
                ) {
                    newFormArray.push(feedbackOption);
                }
            }

            // Update form items.
            action.form = newFormArray;
            action.pages =
                action.singlePageForm === true ? 1 : newFormArray.length

            // Add page id's
            for (var i = 0; i < action.form.length; i++) {
                var feedbackOption = action.form[i];
                if (action.singlePageForm === true) {
                    feedbackOption.page = 0;
                } else {
                    feedbackOption.page = i;
                }
            }

            // Inject email collection.
            if (action.collectEmail === true || action.collectEmail === undefined) {
                var defaultValue = undefined;
                const session = useSessionStore.getState().session;
                if (session && session.userId && session.userId.length > 0 && session.email && session.email.length > 0 && validateEmail(session.email)) {
                    defaultValue = session.email;
                }

                action.form.push({
                    title: "Email",
                    placeholder: "Your e-mail",
                    type: "text",
                    inputtype: "email",
                    name: "reportedBy",
                    required: true,
                    remember: true,
                    defaultValue,
                    hideOnDefaultSet: true,
                    page: action.form[action.form.length - 1].page,
                });
            }

            // Inject privacy policy.
            if (!action.disableUserScreenshot || action.enableUserScreenRecording) {
                var captureItem = {
                    name: "capture",
                    type: "capture",
                    enableScreenshot: !action.disableUserScreenshot
                        ? true
                        : false,
                    autostartDrawing: true,
                    enableCapture: action.enableUserScreenRecording
                        ? true
                        : false,
                    captureTitle: action.captureTitle ? action.captureTitle : "Record screen",
                    screenshotTitle: action.screenshotTitle ? action.screenshotTitle : "Mark the bug",
                    page: action.form[action.form.length - 1].page,
                };
                action.form.push(captureItem);
            }

            // Inject privacy policy.
            if (action.privacyPolicyEnabled) {
                var policyItem = {
                    name: "privacypolicy",
                    type: "privacypolicy",
                    required: true,
                    url: action.privacyPolicyUrl,
                    page: action.form[action.form.length - 1].page,
                };
                action.form.push(policyItem);
            }

            // Check if we have default values available.
            for (var i = 0; i < action.form.length; i++) {
                var feedbackOption = action.form[i];
                const value = get().preFillData[feedbackOption.name];
                if (value !== undefined) {
                    feedbackOption.defaultValue = value;
                }
            }
        }

        set({
            action,
        });
    },
    setFormSent: () => {
        set({
            formSent: true,
            sendingForm: false
        });
    }
}))